@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.nav {
}
*::-webkit-scrollbar {
  display: none;
}
body {
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
  height: 100vh;
}
.overflow {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  top: 0;
}
.overviewCard {
  z-index: 30;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: fixed;
}
.header__profile {
  position: relative;
}
.header__profile::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #0455ba;
  top: 35px;
  transform: translate(-50%, -50%);
  left: 50%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.header__details {
  position: relative;
}
.header__details::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: #2d78db;
  top: 45px;
  transform: translate(-50%, -50%);
  left: 50%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
@media screen and (min-width: 1024px) {
  .header__details::before {
    display: none; /* hide the element on large screens */
  }
}
.upload__img {
  z-index: 30;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: fixed;
}
.placeholder-start::placeholder {
  text-align: start;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f87da9;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.css-1riniog-MuiFormControlLabel-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.gridCourses {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
.custom-checkbox__login input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom-checkbox__login .checkmark__login {
  position: relative;
  display: inline-block;
  /* width: 20px;
  height: 20px; */
  /* background-color: #0455ba; */
  /* border: 3px solid #fff; */
  border-radius: 4px;
  cursor: pointer;
}

/* Style the custom checkbox when it's checked */
.custom-checkbox__login input:checked + .checkmark__login {
  background-color: #000;
  border: 1px solid #000;
}

/* Create a checkmark symbol using pseudo-elements */
.custom-checkbox__login .checkmark__login:after {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #38aee6;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox__login input:checked + .checkmark__login:after {
  display: block;
}

/* Style the label text */
.custom-checkbox__login label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

/* When the checkbox is checked, change the text color */
.custom-checkbox__login input:checked + .checkmark + label {
  color: #2196f3;
}

/* Hide default checkbox */
input[type="checkbox"] {
  display: none;
}

.correct[type="checkbox"]:checked + label:before {
  background-color: #40a726; /* Updated color when checked */
  border-color: #40a726; /* Updated border color when checked */
}
.worng[type="checkbox"]:checked + label:before {
  background-color: #dd0303; /* Updated color when checked */
  border-color: #dd0303; /* Updated border color when checked */
}
/* Style the custom checkbox/radio */
/* Hide default checkbox */
input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox/radio */
input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px; /* Adjust the spacing based on your preference */
  cursor: pointer;
}

/* Create the custom checkbox/radio indicator */
input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px; /* Adjust the size of the indicator */
  height: 20px; /* Adjust the size of the indicator */
  border: 2px solid #333; /* Border color */
  background-color: #fff; /* Background color when not checked */
  border-radius: 50%; /* Make it circular */
  box-sizing: border-box;
}

/* Style the custom checkbox/radio when checked */

/* Add a border around the active (checked) checkbox */
input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 3px; /* Adjust the spacing between the checkbox and the border */
  top: 3px; /* Adjust the spacing between the checkbox and the border */
  width: 14px; /* Adjust the size of the border */
  height: 14px; /* Adjust the size of the border */
  border: 2px solid #fff; /* Border color */
  border-radius: 50%; /* Make it circular */
}

/* Style the label text (optional) */
input[type="checkbox"] + label span {
  padding-left: 10px; /* Adjust the spacing between the indicator and text */
}
